<template>
    <div class="layout">
        <div class="overlay" v-if="loading"></div>
        <div style="z-index: 100 !important;">
            <SideNavbar @button-click="langchange"></SideNavbar>
        </div>
    
        <!--Access denied-->
        <div class="loading-card" v-if="page_unavailable">
            <div class="loading-content">
                <div class="loading-text">
                    <img src="../assets/multiply.png" class="img_style_denied_perms" style="margin-left: 15px;">
                    <p class="notiftext_title_denied_perms" style="margin-left: 20px;">{{
                        this.lang.popup_card_access_denied[0] }}</p>
                    <p class="notiftext_denied_perms">{{ this.lang.popup_card_access_denied[1] }}</p>
                    <button type="submit" class="mandev_btn text-center"
                        style="margin-left: 15px; margin-top: 20px;" @click="goBack">{{
                        this.lang.popup_card_access_denied[2] }}</button>
                </div>
            </div>
        </div>
        <!-- Corporate Device List -->
        <div class="regdevice_contents">
            <div class="regdevice_header">
                {{ this.lang.title }}
            </div>
            <div class="dashboard_role">
                <b>Current Company Selection: </b> {{ this.current_company_selection }}
            </div>
            <BackButton @click="goBack" :message="this.lang.back_button"></BackButton>
            <div class="searchbar_container form-group form_margin">
                <input type="text" class="form-control custom_input_form" id="devicename" :placeholder="this.lang.msisdn_hint" v-model="serial_number" />
                <button type="submit" class="editacc_btn" @click="list_corp_devices">{{ this.lang.search_button }}</button>
                <div style="display: flex; width: 508px; margin-left: 30%;">
                    <label style="margin-top: 6px; font-family: 'Century Gothic Bold';">{{ this.lang.text_select_options }}: </label>
                    <select type="select-category" @change="list_corp_devices"
                    class="form-control contact_input_form" id="select-device-category" v-model="select_device_option" style="max-width: 200px; max-height: 40px; margin-left: 10px; margin-bottom: 20px;">
                    <option value="ios">iOS</option>
                    <option value="ipados">iPadOS</option>
                    <option value="macos">MacOS</option>
                    </select>
                </div>
            </div>
            <div class="table_container">
                <table class="table_styles">
                    <thead class="table_header">
                        <tr>
                            <th class="tablehead_text">No.</th>
                            <th class="tablehead_text">{{ this.lang.pos_label1 }}</th>
                            <th class="tablehead_text">{{ this.lang.pos_label2 }}</th>
                            <th class="tablehead_text">{{ this.lang.pos_label3 }}</th>
                            <th class="tablehead_text">{{ this.lang.pos_label5 }}</th>
                            <th class="tablehead_text">{{ this.lang.pos_label6 }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in tableDataCorp" :key="index" :class="{'odd_row': index%2 === 0, 'even_row': index%2 !== 0}" class="table_body_border">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.deviceName }}</td>
                            <td>{{ item.serialNumber }}</td>
                            <td>{{ item.contractEntry }}</td>
                            <td>{{ item.contractTenure }}</td>
                            <td>
                                <img src="../assets/actions_icon.png" alt="Logo" class="logo" @click="performAction(item)" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!--Access denied-->
        <div class="loading-card" v-if="page_unavailable">
            <div class="loading-content">
                <div class="loading-text">
                    <img src="../assets/multiply.png" class="img_style_denied_perms" style="margin-left: 15px;">
                    <p class="notiftext_title_denied_perms" style="margin-left: 20px;">{{
                        this.lang.popup_card_access_denied[0] }}</p>
                    <p class="notiftext_denied_perms">{{ this.lang.popup_card_access_denied[1] }}</p>
                    <button type="submit" class="mandev_btn text-center"
                        style="margin-left: 15px; margin-top: 20px;" @click="goBack">{{
                            this.lang.popup_card_access_denied[2] }}</button>
                </div>
            </div>
        </div>
        <BottomFooter ref="BottomFooterRef"></BottomFooter>
    </div>
</template>
<script>
    import SideNavbar from './SideNavbar.vue';
    import BottomFooter from './BottomFooter.vue';
    import BackButton from './BackButton.vue';

    export default {
        components: {
            SideNavbar,
            BottomFooter,
            BackButton
        },
        data() {
            return{
                loading: false,
                page_unavailable: false,
                current_company_selection: localStorage.current_company_selection,
                select_device_option:'ios',
                serial_number: '',
                tableDataCorp: [],
                lang:{
                    title:"",
                    second_title:"",
                    content:"",
                    msisdn_hint: '',
                    search_button: '',
                    pos_label1: '',
                    pos_label2: '',
                    pos_label3: '',
                    pos_label4: '',
                    pos_label5: '',
                    pos_label6: '',
                    popup_card_access_denied: [
                        "Access Denied",
                        "Please return to the homepage",
                        "OK"
                    ]
                }
            };
        },
        methods: {
            async langchange(picker){
                console.log(picker)
                console.log(this.data)
                const response = await fetch("/corpdevicelist.json");
                const data = await response.json();
                this.data = data
                this.lang = this.data[picker]
                this.$refs.BottomFooterRef.langchange(picker)
            },
            performAction(v){
                
                localStorage.selectedmodel = v.deviceName;
                localStorage.selected = v.serialNumber;
                localStorage.selectedstorage = v.storage;
                localStorage.selectedStartContractDate = v.contractEntry;
                localStorage.selectedLastTrigger = v.lastTrigger;
                localStorage.selectedContractTenure = v.contractTenure;
                if (v.locked === 0){
                    this.$router.replace('/device-details');
                }
                else{
                    this.$router.replace('/device-lost-mode');
                }
            },
            list_corp_devices() {
                // Prepare axios request
                const axios = require('axios');
                let data = null;
                let real_device_type = null;
                if (this.select_device_option == "ios") {
                        real_device_type = "iPhone"
                    } else if (this.select_device_option == "ipados") {
                        real_device_type = "iPad";
                    } else if (this.select_device_option == "macos") {
                        real_device_type = "Mac";
                    } else {
                        real_device_type = "";
                    }
                if (this.serial_number != '' && this.select_device_option != '') {
                    data = JSON.stringify({
                        session_id: localStorage.token,
                        company_id: localStorage.selected_company_id,
                        serial_number: this.serial_number,
                        device_type: real_device_type
                    })
                } else if (this.serial_number != '') {
                    data = JSON.stringify({
                        session_id: localStorage.token,
                        company_id: localStorage.selected_company_id,
                        serial_number: this.serial_number
                    })
                } else if (this.select_device_option != '') {
                    data = JSON.stringify({
                        session_id: localStorage.token,
                        company_id: localStorage.selected_company_id,
                        device_type: real_device_type
                    })
                } else {
                    data = JSON.stringify({
                        session_id: localStorage.token,
                        company_id: localStorage.selected_company_id
                    })
                }
                let config = {
                    method: 'POST',
                    maxBodyLength: Infinity,
                    url: process.env.VUE_APP_BACKEND_URL+'/v1/device-details/get-all-from-company',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    data: data
                };

                // Run axios
                axios.request(config)
                    .then((response) => {
                    console.log(JSON.stringify(response.data));

                    if (response.status == 200) {
                        // Loop through the "200" data and populate tableData
                        this.tableData = response.data['result']
                        console.log(this.tableData)
                        this.tableDataCorp = response.data["result"].map(item => ({
                            deviceName:item[3],
                            serialNumber:item[2],
                            storage:item[4],
                            contractEntry:item[7],
                            contractTenure: item[8],
                            lastTrigger: item[9],
                            locked: item[5]
                        }));
                        this.success = true;
                    }
                })        
            },
            
            goBack() {
                this.$router.replace('/device-list');
            }
        },
        mounted() {
            this.list_corp_devices();
            localStorage.selectedDeviceType = 'enterprise';
        }
    }
</script>
<style scoped>
    .regdevice_contents{
        flex: 1;
        padding-left: 16px;
        font-family: 'Century Gothic';
    }
    .regdevice_header{
        font-family: 'Century Gothic Bold';
        font-weight: bold;
        font-size: 24px;
        margin-top: 10px;
    }
    .loading-card {
        position: fixed;
        width: 500px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 28px;
        filter: brightness(98%);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .loading-text {
        justify-items: center;
        display: grid;
        font-family: CenturyGothic, sans-serif;
        text-align: center;
    }

    .layout{
        display: flex;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
        z-index: 9998; /* Below the loading content */
    }

    .notiftext_denied_perms {
        font-family: 'Century Gothic';
        text-align: center !important;
        font-size: 12px;
        color: #000000;
        margin-bottom: 8px;
    }

    .mandev_btn {
        text-align: center;
        width: 150px;
        height: 30px;
        margin-bottom: 5px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 10px;
        border: none;
        border-radius: 28px;
        padding: 0.3vw;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 500;
    }

    .mandev_btn:hover {
        filter: brightness(90%);
    }

    .notiftext_title_denied_perms {
       font-family: 'Century Gothic Bold';
        font-size: 16px;
        color: #000000;
        margin-bottom: 2px;
    }

    .img_style_denied_perms {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        left: 10px;
    }

    ::placeholder{
        font-size: 12px;
    }
    .table_container{
        margin-top: 10px;
        max-width: 95%;
        max-height: 440px;
        overflow-y: auto;
    }
    .table_container::-webkit-scrollbar{
        width: 8px;
    }
    .table_container::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
    }
    .table_container::-webkit-scrollbar-track {
        background-color: #eee;
        border-radius: 4px;
    }
    .table_styles{
        width: 100%;

    }
    thead tr{
        height: 50px;
    }
    th, td{
        text-align: center;
        padding: 16px;
    }
    .odd_row{
        background-color: #ffffff;
    }
    .even_row{
        background-color: #e6e6e6;
    }
    .table_header{
        background-color: #cccccc;
        text-align: center;
        border-bottom: 1px solid #989898;
    }
    .table_body_border{
        border-bottom: 1px solid #989898;
    }
    .tablehead_text{
        font-family: "Century Gothic Bold", sans-serif;
        font-size: 14px;
        text-align: center;
    }

    .custom_input_form{
        background-color: #ffffff;
        height: 35px;
        width: 300px;
        text-decoration: none solid rgba(0, 0, 0, 0.87);
        border-color: rgba(1, 48, 66, 0.2) !important;
        word-spacing: 0px;
        letter-spacing: 0.131118px;
        font-size: 12px !important;
        box-sizing: border-box;
        outline: rgba(0, 0, 0, 0.87) none 0px;
    }
    .custom_input_form:focus{
        outline: none !important;
        box-shadow: none !important;
        border-color: rgba(1, 48, 66, 0.8) !important;
        border-width: 2.5px;
        background-color: #ffffff;
        color: #000000;
        font-size: 12px;
    }
    .editacc_btn{
        margin-left: 10px;
        width: 100px;
        height: 35px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 11px;
        border: none;
        border-radius: 5px;
        padding: 0.3vw;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 500;
    }
    .editacc_btn:hover{
        filter: brightness(90%);
    }
    .searchbar_container{
        padding-top: 10px;
        display: flex;
    }
</style>