import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import LoginContents from "./components/LoginScreen.vue";
import StartingDashboard from "./components/StartDashboard.vue";
import FAQ from "./components/FAQScreen.vue";
import RegisterDevice from "./components/RegisterDevice.vue";
import DeviceList from "./components/DeviceList.vue";
import DeviceDetails from "./components/DeviceDetails.vue";
import LostMode from "./components/LostMode.vue";
import DeviceLocation from "./components/DeviceLocation.vue";
import UserProfile from "./components/UserProfile.vue";
import EditAccount from "./components/EditAccount.vue";
import ManageTemplates from "./components/ManageTemplates.vue";
import ManageUserAdmin from "./components/ManageUserAdmin.vue";
import DeviceReporting from "./components/DeviceReporting.vue";
import SettingsPage from "./components/SettingsPage.vue";
import CreditDashboard from "./components/credit_system/CreditDashboard.vue";
import TopupPage from "./components/credit_system/TopupPage.vue";
import CreditStatus from "./components/credit_system/CreditStatus.vue";
import CorpDeviceList from "./components/CorpDeviceList.vue";
import SettlementPage from "./components/credit_system/SettlementPage.vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap";

const app = createApp(App);

const routes = [
  {
    path: "/",
    components: {
      default: LoginContents,
    },
    params: true,
  },
  {
    path: "/dashboard",
    component: StartingDashboard,
  },
  {
    path: "/faq",
    component: FAQ,
  },
  {
    path: "/register-devices",
    component: RegisterDevice,
  },
  {
    path: "/device-list",
    component: DeviceList,
  },
  {
    path: "/device-details",
    component: DeviceDetails,
  },
  {
    path: "/enterprise-device-details",
    component: CorpDeviceList,
  },
  {
    path: "/device-lost-mode",
    component: LostMode,
  },
  {
    path: "/device-location",
    component: DeviceLocation,
  },
  {
    path: "/profile",
    component: UserProfile,
  },
  {
    path: "/edit-account",
    component: EditAccount,
  },
  {
    path: "/manage-templates",
    component: ManageTemplates,
  },
  {
    path: "/manage-user-admin",
    component: ManageUserAdmin,
  },
  {
    path: "/reporting",
    component: DeviceReporting,
  },
  {
    path: "/settings",
    component: SettingsPage,
  },
  {
    path: "/credit-manager",
    component: CreditDashboard,
  },
  {
    path: '/credit-manager/settlement',
    component: SettlementPage,
  },
  {
    path: "/credit-manager/topup",
    component: TopupPage,
  },
  {
    path: "/credit-manager/status",
    component: CreditStatus,
    params: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes
});
Sentry.init({
  app,
  dsn: "https://95c61ae1733fcecc4c7b04cf43babd81@o4505485535346688.ingest.us.sentry.io/4506620488908800",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default router 
app.use(router).mount("#app");
