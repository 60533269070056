<template>
  <div class="layout">
    <div class="overlay" v-if="loading" data-testid="loading-overlay"></div>
    <div style="z-index: 100 !important">
      <SideNavbar @button-click="langchange"></SideNavbar>
    </div>
    <div class="regdevice_contents">
      <div class="regdevice_header">
        {{ this.lang.title }}
      </div>
      <div class="dashboard_role">Current Role : {{ this.currentRole }}</div>

      <div v-if="tableData.length > 0">
        <!-- <p class="regdevice_secondary_header">You are at page {{ currentIndex[0] }} out of {{ tableData.length }}</p>
                <button @click="navigateTables('previous', 0), clearSelectedOption()"  :disabled="isPreviousDisabled(0)">Previous Page</button>
                <button @click="navigateTables('next', 0), clearSelectedOption()" :disabled="isNextDisabled(this.lang.tables, 0)">Next Page</button> -->
        <button
          @click="navigateTables2(0, 1), clearSelectedOption()"
          data-testid="in-lost-mode-button"
        >
          Going In Lost Mode
        </button>
        <button
          @click="navigateTables2(0, 2), clearSelectedOption()"
          data-testid="out-lost-mode-button"
        >
          Going Out Lost Mode
        </button>
        <button
          @click="navigateTables2(0, 3), clearSelectedOption()"
          data-testid="registered-devices-button"
        >
          Registered Devices
        </button>
        <button
          @click="navigateTables2(0, 4), clearSelectedOption()"
          data-testid="audit-trail-button"
        >
          Audit Trail
        </button>

        <div
          v-for="(table, index) in this.lang.tables"
          :key="index"
          v-show="index == currentIndex[0]"
        >
          <br />

          <div class="regdevice_secondary_container">
            <div v-if="index != 4">
              <button
                @click="downloadCSV2()"
                :data-testid="`download-report-button-${index}`"
              >
                Download Reporting Table as CSV
              </button>
            </div>

            <div v-if="index == 4">
              <button @click="downloadCSV(index - 1)">
                Download Audit Trail Table as CSV
              </button>
              <br />
              <div style="display: flex">
                <p>Search Action Log:</p>
                <input
                  type="text"
                  v-model="searchbar"
                  style="margin-left: 5px; height: 25px"
                />
              </div>
            </div>
            <button @click="clearSelectedOption(), handleStatusChange(index)">
              Reset Selection
            </button>
            <br />
            <br />

            <div style="display: flex">
              <div>
                <label for="year">Select a year:</label>
                <select
                  id="year"
                  v-model="selectedYear"
                  @change="handleYearChange"
                >
                  <option
                    v-for="year in availableYears"
                    :key="year"
                    :value="year"
                  >
                    {{ year }}
                  </option></select
                ><br /><br />
              </div>
              <div v-if="index == 3 || index == 4" style="padding-left: 10px">
                <label>Select a status:</label>
                <select
                  id="contractStatus"
                  v-model="selectedStatus"
                  @change="handleStatusChange(index)"
                >
                  <option
                    v-for="status in availableStatus"
                    :key="status"
                    :value="status"
                  >
                    {{ status }}
                  </option></select
                ><br /><br />
              </div>
            </div>
          </div>
          <!-- <div class="searchbar_container form-group form_margin">
                        <input type="text" class="form-control custom_input_form" id="devicename" :placeholder="table.msisdn_hint" v-model="devicename" />
                        <button type="submit" class="editacc_btn" @click="searchDevice">{{ table.search_button }}</button>
                    </div> -->

          <div v-if="selectedYear" class="table_container">
            <div name="monthSection">
              <p class="regdevice_secondary_header">Monthly</p>
              <div
                v-for="(month, monthIndex) in months"
                :key="monthIndex"
                v-show="monthIndex == currentIndex[1] - 1"
              >
                <p class="contents_header_text">{{ month }}</p>

                <button
                  @click="
                    navigateTables('previous', 1),
                      updateSelectedMonth(months[monthIndex - 1])
                  "
                  :disabled="isPreviousDisabled(1)"
                >
                  Previous Month
                </button>
                <button
                  @click="
                    navigateTables('next', 1),
                      updateSelectedMonth(months[monthIndex + 1])
                  "
                  :disabled="isNextDisabled(this.months, 1)"
                >
                  Next Month
                </button>
                <br />
                <br />

                <table class="table_styles">
                  <thead class="table_header">
                    <tr>
                      <th class="tablehead_text">No.</th>
                      <th
                        v-for="(key, index) in filteredKeys(table)"
                        :key="index"
                        class="tablehead_text"
                      >
                        {{ table[key] }}
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="selectedStatus">
                    <div
                      v-for="(item, itemIndex) in tableData[index - 1]"
                      :key="itemIndex"
                      :data-testid="`tableData${index - 1}`"
                    >
                      <tr
                        v-if="
                          dateContainsMonth(item, month) &&
                          dateContainsYear(item, selectedYear) &&
                          statusContainsSelectedStatus(item, selectedStatus)
                        "
                        :class="{
                          odd_row: itemIndex % 2 === 0,
                          even_row: itemIndex % 2 !== 0,
                        }"
                        class="table_body_border"
                      >
                        <td>{{ itemIndex + 1 }}</td>
                        <template v-for="(value, key) in item">
                          <td v-if="!checkHiddenKey(key)" :key="key">
                            {{ value }}
                          </td>
                        </template>
                      </tr>
                    </div>
                  </tbody>
                  <tbody v-else>
                    <div
                      v-for="(item, itemIndex) in tableData[index - 1]"
                      :key="itemIndex"
                      :data-testid="`tableData${index - 1}`"
                    >
                      <tr
                        v-if="
                          dateContainsMonth(item, month) &&
                          dateContainsYear(item, selectedYear)
                        "
                        :class="{
                          odd_row: itemIndex % 2 === 0,
                          even_row: itemIndex % 2 !== 0,
                        }"
                        class="table_body_border"
                      >
                        <td>{{ itemIndex + 1 }}</td>
                        <template v-for="(value, key) in item">
                          <td v-if="!checkHiddenKey(key)" :key="key">
                            {{ value }}
                          </td>
                        </template>
                      </tr>
                    </div>
                  </tbody>
                </table>
              </div>
            </div>

            <div name="weekSection">
              <p class="regdevice_secondary_header">Weekly</p>
              <div
                v-for="(week, weekIndex) in weeks"
                :key="weekIndex"
                v-show="weekIndex == currentIndex[2] - 1"
              >
                <p class="contents_header_text">Week {{ week }}</p>
                <button
                  @click="
                    navigateTables('previous', 2), updateSelectedWeek(week - 1)
                  "
                  :disabled="isPreviousDisabled(2)"
                >
                  Previous Week
                </button>
                <button
                  @click="
                    navigateTables('next', 2), updateSelectedWeek(week + 1)
                  "
                  :disabled="isNextDisabled(this.weeks, 2)"
                >
                  Next Week
                </button>
                <br />
                <br />
                <table class="table_styles">
                  <thead class="table_header">
                    <tr>
                      <th class="tablehead_text">No.</th>
                      <th
                        v-for="(key, index) in filteredKeys(table)"
                        :key="index"
                        class="tablehead_text"
                      >
                        {{ table[key] }}
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="selectedStatus">
                    <div
                      v-for="(item, itemIndex) in tableData[index - 1]"
                      :key="itemIndex"
                      :data-testid="`tableData${index - 1}`"
                    >
                      <tr
                        v-if="
                          dateContainsWeek(item, selectedMonth, week) &&
                          statusContainsSelectedStatus(item, selectedStatus)
                        "
                        :class="{
                          odd_row: itemIndex % 2 === 0,
                          even_row: itemIndex % 2 !== 0,
                        }"
                        class="table_body_border"
                      >
                        <td>{{ itemIndex + 1 }}</td>
                        <template v-for="(value, key) in item">
                          <td v-if="!checkHiddenKey(key)" :key="key">
                            {{ value }}
                          </td>
                        </template>
                      </tr>
                    </div>
                  </tbody>
                  <tbody v-else>
                    <div
                      v-for="(item, itemIndex) in tableData[index - 1]"
                      :key="itemIndex"
                      :data-testid="`tableData${index - 1}`"
                    >
                      <tr
                        v-if="dateContainsWeek(item, selectedMonth, week)"
                        :class="{
                          odd_row: itemIndex % 2 === 0,
                          even_row: itemIndex % 2 !== 0,
                        }"
                        class="table_body_border"
                      >
                        <td>{{ itemIndex + 1 }}</td>
                        <template v-for="(value, key) in item">
                          <td v-if="!checkHiddenKey(key)" :key="key">
                            {{ value }}
                          </td>
                        </template>
                      </tr>
                    </div>
                  </tbody>
                </table>
              </div>
            </div>

            <div name="daySection">
              <p class="regdevice_secondary_header">Daily</p>

              <div
                v-for="(day, dayIndex) in days"
                :key="dayIndex"
                v-show="dayIndex == currentIndex[3] - 1"
              >
                <p class="contents_header_text">
                  {{ addDaySuffix(day) }} of {{ actualMonth }}
                </p>
                <button
                  @click="navigateTables('previous', 3)"
                  :disabled="isPreviousDisabled(3)"
                >
                  Previous Day
                </button>
                <button
                  @click="navigateTables('next', 3)"
                  :disabled="isNextDisabled(this.days, 3)"
                >
                  Next Day
                </button>
                <br />
                <br />
                <table class="table_styles">
                  <thead class="table_header">
                    <tr>
                      <th class="tablehead_text">No.</th>
                      <th
                        v-for="(key, index) in filteredKeys(table)"
                        :key="index"
                        class="tablehead_text"
                      >
                        {{ table[key] }}
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="selectedStatus">
                    <div
                      v-for="(item, itemIndex) in tableData[index - 1]"
                      :key="itemIndex"
                      :data-testid="`tableData${index - 1}`"
                    >
                      <tr
                        v-if="
                          dateContainsDay(item, selectedMonth, day) &&
                          statusContainsSelectedStatus(item, selectedStatus)
                        "
                        :class="{
                          odd_row: itemIndex % 2 === 0,
                          even_row: itemIndex % 2 !== 0,
                        }"
                        class="table_body_border"
                      >
                        <td>{{ itemIndex + 1 }}</td>
                        <template v-for="(value, key) in item">
                          <td v-if="!checkHiddenKey(key)" :key="key">
                            {{ value }}
                          </td>
                        </template>
                      </tr>
                    </div>
                  </tbody>
                  <tbody v-else>
                    <!-- <tr v-for="(item, itemIndex) in tableData[index - 1]" :key="itemIndex" :class="{'odd_row': itemIndex%2 === 0, 'even_row': itemIndex%2 !== 0}" class="table_body_border">
                                            <td>{{ itemIndex + 1 }}</td>
                                            <template v-for="(value, key) in item">

                                                <td v-if="!checkHiddenKey(key)" :key="key">{{ value }}</td>
                                            </template>
                                        </tr> -->
                    <div
                      v-for="(item, itemIndex) in tableData[index - 1]"
                      :key="itemIndex"
                      :data-testid="`tableData${index - 1}`"
                    >
                      <tr
                        v-if="dateContainsDay(item, selectedMonth, day)"
                        :class="{
                          odd_row: itemIndex % 2 === 0,
                          even_row: itemIndex % 2 !== 0,
                        }"
                        class="table_body_border"
                      >
                        <td>{{ itemIndex + 1 }}</td>
                        <template v-for="(value, key) in item">
                          <td v-if="!checkHiddenKey(key)" :key="key">
                            {{ value }}
                          </td>
                        </template>
                      </tr>
                    </div>
                  </tbody>
                </table>
              </div>

              <!-- <div v-for="(day, dayIndex) in days" :key="dayIndex" v-show="dayIndex == currentIndex[3] - 1">
                                <p class="contents_header_text">{{ day }}</p>

                            </div> -->
            </div>
          </div>

          <div v-else class="table_container">
            <p class="regdevice_secondary_header">{{ table.table_title }}</p>
            <p class="contents_header_text">{{ table.content }}</p>
            <table class="table_styles">
              <thead class="table_header">
                <tr>
                  <th class="tablehead_text">No.</th>
                  <th
                    v-for="(key, index) in filteredKeys(table)"
                    :key="index"
                    class="tablehead_text"
                  >
                    {{ table[key] }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="selectedStatus">
                <div
                  v-for="(item, itemIndex) in tableData[index - 1]"
                  :key="itemIndex"
                  :data-testid="`tableData${index - 1}`"
                >
                  <tr
                    v-if="statusContainsSelectedStatus(item, selectedStatus)"
                    :class="{
                      odd_row: itemIndex % 2 === 0,
                      even_row: itemIndex % 2 !== 0,
                    }"
                    class="table_body_border"
                  >
                    <td>{{ itemIndex + 1 }}</td>
                    <template v-for="(value, key) in item">
                      <td v-if="!checkHiddenKey(key)" :key="key">
                        {{ value }}
                      </td>
                    </template>
                  </tr>
                </div>
              </tbody>
              <tbody v-if="searchbar != '' && currentIndex[0] == 4">
                <div
                  v-for="(item, itemIndex) in tableData[index - 1]"
                  :key="itemIndex"
                  :data-testid="`tableData${index - 1}`"
                >
                  <tr
                    v-if="actionContainsSelectedAction(item, searchbar)"
                    :class="{
                      odd_row: itemIndex % 2 === 0,
                      even_row: itemIndex % 2 !== 0,
                    }"
                    class="table_body_border"
                  >
                    <td>{{ itemIndex + 1 }}</td>
                    <template v-for="(value, key) in item">
                      <td v-if="!checkHiddenKey(key)" :key="key">
                        {{ value }}
                      </td>
                    </template>
                  </tr>
                </div>
              </tbody>
              <tbody v-else>
                <tr
                  v-for="(item, itemIndex) in tableData[index - 1]"
                  :key="itemIndex"
                  :data-testid="`tableData${index - 1}`"
                  :class="{
                    odd_row: itemIndex % 2 === 0,
                    even_row: itemIndex % 2 !== 0,
                  }"
                  class="table_body_border"
                >
                  <td>{{ itemIndex + 1 }}</td>
                  <template v-for="(value, key) in item">
                    <!-- Add a condition to exclude the 'deviceId' key -->
                    <td v-if="!checkHiddenKey(key)" :key="key">{{ value }}</td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="regdevice_secondary_header">Loading...</p>
      </div>
      <!--Access denied-->
      <div class="loading-card" v-if="page_unavailable">
        <div class="loading-content">
          <div class="loading-text">
            <img
              src="../assets/multiply.png"
              class="img_style_denied_perms"
            />
            <p class="notiftext_title_denied_perms">
              {{ this.lang.popup_card_access_denied[0] }}
            </p>
            <p class="notiftext_denied_perms">
              {{ this.lang.popup_card_access_denied[1] }}
            </p>
            <button
              type="submit"
              class="mandev_btn text-center"
              style="margin-top: 10px"
              @click="pageUnavailableReturn"
            >
              {{ this.lang.popup_card_access_denied[2] }}
            </button>
          </div>
        </div>
      </div>
      <div class="loading-card" v-if="demoMode">
        <div class="loading-content">
          <div class="loading-text">
            <img
              src="../assets/multiply.png"
              class="img_style_denied_perms"
            />
            <p class="notiftext_title_denied_perms">
              {{ this.lang.popup_card_demo[0] }}
            </p>
            <p class="notiftext_denied_perms">
              {{ this.lang.popup_card_demo[1] }}
            </p>
            <button
              type="submit"
              class="mandev_btn text-center"
              style="margin-top: 10px"
              @click="onCloseDemoWarning"
            >
              {{ this.lang.popup_card_demo[2] }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <BottomFooter ref="BottomFooterRef"></BottomFooter>
</template>

<script>
import SideNavbar from "./SideNavbar.vue";
import BottomFooter from "./BottomFooter.vue";

export default {
  components: {
    SideNavbar,
    BottomFooter,
  },
  data() {
    return {
      is_able_to_login: false,
      loading: true,
      success: false,
      tryagainWarning: false,
      tableData: [],
      csvData: [],
      lang: {},
      ignoredKeys: ["hidden"],
      currentIndex: [1, 1, 1, 1],
      selectedYear: null,
      selectedStatus: null,
      lostModeDataCount: 0,
      currentRole: localStorage.role,
      csvDataCount: 0,
      startYear: 2020, // Start year
      endYear: new Date().getFullYear(),
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      weeks: [1, 2, 3, 4],
      days: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      availableStatus: ["Active", "Barring", "Suspension", "Termination"],
      selectedMonth: "Jan",
      actualMonth: "January",
      page_unavailable: false,
      demoMode: false,
      selectedWeek: 1,
      searchbar: "",
      gmt_offset: null,
    };
  },
  computed: {
    filteredKeys() {
      return function (table) {
        return Object.keys(table).filter((key) => key.includes("pos_label"));
      };
    },
    availableYears() {
      const years = [];
      for (let year = this.endYear; year >= this.startYear; year--) {
        years.push(year);
      }
      return years;
    },
  },

  methods: {
    getUserGMTOffset() {
      const now = new Date();
      const timezoneOffsetMinutes = now.getTimezoneOffset();
      const timezoneOffsetHours = -timezoneOffsetMinutes / 60;
      const gmtOffset = `GMT${
        timezoneOffsetHours >= 0 ? "+" : ""
      }${timezoneOffsetHours}`;
      this.gmt_offset = gmtOffset;
      console.log("GMT: " + this.gmt_offset);
    },
    addDaySuffix(day) {
      if (day == 1 || day == 21 || day == 31) {
        return day + "st";
      } else if (day == 2 || day == 22) {
        return day + "nd";
      } else if (day == 3 || day == 23) {
        return day + "rd";
      } else {
        return day + "th";
      }
    },
    updateSelectedMonth(month) {
      this.selectedMonth = month.substring(0, 3);
      this.actualMonth = month;
    },
    updateSelectedWeek(week) {
      this.selectedWeek = week;
    },
    dateContainsMonth(item, month) {
      const monthAbbreviation = month.substring(0, 3);
      // Check if the item's date includes the abbreviated month
      if (item.date && item.date.includes(monthAbbreviation)) {
        return true;
      }
      return false;
    },
    dateContainsYear(item, year) {
      // Check if the item's date includes the abbreviated month
      if (item.date && item.date.includes(year)) {
        return true;
      }
      return false;
    },
    dateContainsWeek(item, month, week) {
      if (item.date && item.date.includes(month)) {
        let date = new Date(item.date);
        const day = date.getDate();
        if (day == 29 || day == 30 || day == 31) {
          return 4 == week;
        } else {
          return Math.ceil(day / 7) == week;
        }
      } else {
        return null;
      }
    },
    dateContainsDay(item, month, day) {
      if (day < 10) {
        day = "0" + day;
      }
      if (
        item.date &&
        item.date.substring(5, 7) == day &&
        item.date.substring(8, 11) == month
      ) {
        return true;
      } else {
        return false;
      }
    },
    statusContainsSelectedStatus(item, status) {
      if (item.contractStatus && item.contractStatus.includes(status)) {
        return true;
      }
      return false;
    },
    actionContainsSelectedAction(item, action) {
      if (item.actionLog && item.actionLog.includes(action)) {
        return true;
      }
      return false;
    },
    downloadCSV2() {
      // Get the specific tableData using the provided index
      let specificTableData = this.csvData.map((item) => {
        let newItem = {};
        // Iterate over each key-value pair in item
        for (const key in item) {
          // Check if the key starts with "CSV_"
          if (key.startsWith("CSV_")) {
            // Remove "CSV_" and replace underscores with spaces
            const newKey = key.replace(/^CSV_/, "").replace(/_/g, " ");
            // Assign the value to the corresponding key in newItem
            newItem[newKey] = item[key];
          } else {
            // If key doesn't start with "CSV_", keep it unchanged
            newItem[key] = item[key];
          }
        }
        return newItem;
      });

      /* let keys = this.filteredKeys(this.lang.tables[index + 1]);
                let titleMappings = {};
                keys.forEach(key => {
                    titleMappings[key] = this.lang.tables[index + 1][key];
                });

                let newArray = [];
                let specificKeys = Object.keys(specificTableData[0]);
                specificTableData.forEach((item) => {
                    let newItem = {};
                    let index = 0
                    // Iterate over each key-value pair in titleMappings
                    for (const key in titleMappings) {
                        // Get the value associated with the current key in titleMappings
                        let value = titleMappings[key];

                        // Assign the value as a key in newItem and set its initial value to the corresponding value from specificTableData

                        newItem[value] = item[specificKeys[index]];
                        index = index + 1
                    }

                    // Push the new item to the newArray
                    newArray.push(newItem);
                }); */

      let currentDate = new Date();

      // Extract the year, month, and day components
      let year = currentDate.getFullYear();
      let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because January is 0
      let day = String(currentDate.getDate()).padStart(2, "0");
      let date = `${day}-${month}-${year}`;

      // Convert the specific table data into CSV format
      const csvContent = this.convertToCSV(specificTableData);
      // Create a Blob object containing the CSV data
      const blob = new Blob([csvContent], { type: "text/csv" });
      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Reporting_Table_Data_(${date}).csv`; // Set the file name with the index
      // Trigger a click event to start the download
      link.click();
    },
    downloadCSV(index) {
      // Get the specific tableData using the provided index
      let specificTableData = this.tableData[index].map((item) => {
        let newItem = {};
        // Iterate over each key-value pair in item
        for (const key in item) {
          // Check if the key is not in the ignoredKeys list
          if (!this.checkHiddenKey(key)) {
            // If not, assign the value to the corresponding key in newItem
            newItem[key] = item[key];
          }
        }
        return newItem;
      });

      let keys = this.filteredKeys(this.lang.tables[index + 1]);
      let titleMappings = {};
      keys.forEach((key) => {
        titleMappings[key] = this.lang.tables[index + 1][key];
      });

      let newArray = [];
      let specificKeys = Object.keys(specificTableData[0]);
      specificTableData.forEach((item) => {
        let newItem = {};
        let index = 0;
        // Iterate over each key-value pair in titleMappings
        for (const key in titleMappings) {
          // Get the value associated with the current key in titleMappings
          let value = titleMappings[key];

          // Assign the value as a key in newItem and set its initial value to the corresponding value from specificTableData

          newItem[value] = item[specificKeys[index]];
          index = index + 1;
        }

        // Push the new item to the newArray
        newArray.push(newItem);
      });

      // Convert the specific table data into CSV format
      const csvContent = this.convertToCSV(newArray);
      // Create a Blob object containing the CSV data
      const blob = new Blob([csvContent], { type: "text/csv" });
      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      const tableTitle = this.lang.tables[index + 1].table_title;
      link.download = `table_data_${tableTitle}.csv`; // Set the file name with the index
      // Trigger a click event to start the download
      link.click();
    },
    convertToCSV(data) {
      const headers = Object.keys(data[0]);
      const csvRows = [];

      csvRows.push(headers.join(","));

      // Iterate over each item in the data array
      data.forEach((item) => {
        // Create an array to store the values of each item
        const row = [];

        // Iterate over each key in the item
        for (const key in item) {
          // If the value is a string and contains a comma, enclose it within double quotes
          const value =
            typeof item[key] === "string" && item[key].includes(",")
              ? `"${item[key]}"`
              : item[key];

          // Push the value to the row array
          row.push(value);
        }

        // Join the row array with commas and push it to the csvRows array
        csvRows.push(row.join(","));
      });

      // Join the csvRows array with newline characters to form the CSV content
      return csvRows.join("\n");
    },
    handleDateChange(event) {
      // Handle the date change event
      console.log("Selected date:", event.target.value);
    },
    handleYearChange() {
      console.log("Selected year:", this.selectedYear);
    },
    handleStatusChange(index) {
      const tableIndex = index.toString();
      if (this.selectedStatus == null) {
        this.lang.tables[tableIndex].content = "";
      } else {
        this.lang.tables[tableIndex].content =
          "Table for contract status: " + this.selectedStatus;
      }
    },
    clearSelectedOption() {
      this.selectedYear = null;
      this.selectedStatus = null;
    },

    isPreviousDisabled(index) {
      // Disable previous button if currentIndex is already at the first table
      return this.currentIndex[index] == 1;
    },
    isNextDisabled(object, index) {
      // Check if lang.tables exists and has a length property
      if (object && Array.isArray(Object.keys(object))) {
        // Disable next button if currentIndex is at the last table
        return this.currentIndex[index] == Object.keys(object).length;
      }
      // Return false if lang.tables is null or undefined
      return false;
    },

    searchDevice() {
      console.log("Searching device...");
      this.loadData(document.getElementById("devicename").value);
    },
    checkHiddenKey(key) {
      // Check if the key contains 'hidden'
      return this.ignoredKeys.some((ignore) => key.includes(ignore));
    },
    async loadAllData() {
      try {
        // Execute all methods sequentially
        await this.checkLogin();
        await this.loadData();
        await this.loadEnableLostModeData();
        await this.loadDisableLostModeData();
        await this.loadCSVData();
        await this.loadAuditTrailData();
      } catch (error) {
        console.error("Error loading data on mounted:", error);
      } finally {
        // Disable overlay after all methods are done (or if an error occurs)
      }
    },
    loadData() {
      setTimeout(() => {
        const axios = require("axios");
        let data = JSON.stringify({
          token: localStorage.token,
          MSISDN: "",
        });
        let config = {
          method: "POST",
          maxBodyLength: Infinity,
          url:
            process.env.VUE_APP_BACKEND_URL +
            "/v1/dashboard/display_all_devices/exact",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            //console.log(JSON.stringify(response.data));

            if (response.data && response.data["200"]) {
              // Set data for Registered Devices Table
              this.tableData[2] = response.data["200"].map((item) => ({
                hiddenDeviceId: item[0],
                deviceName: item[1],
                storage: item[2],
                nameUser: item[3],
                serialNumber: item[7],
                date: item[5],
                contractStatus: item[15],
                contractRemaining: item[4] + " months",
                //locked: item[14],
              }));

              this.csvData = response.data["200"].map((item) => ({
                //hiddenDeviceId: item[0],
                CSV_Name: item[3],
                CSV_NRIC: null,
                CSV_MSISDN: item[11],
                CSV_Device_Name: item[1],
                CSV_Storage: item[2],
                CSV_Serial_Number: item[7],
                CSV_Date_Registered: item[5],
                CSV_Tenure: item[4] + " months",
                CSV_Contract_Status: item[15],
                CSV_Last_Action: null,
                CSV_Date_Action: null,
              }));

              // Set success to true if the data is loaded successfully
            } else {
              console.error("Invalid API response"); // Handle the case when the "200" key is missing or data is not in the expected format                    }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 500);
    },
    loadEnableLostModeData() {
      const axios = require("axios");
      let data = JSON.stringify({
        token: localStorage.token,
      });
      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url:
          process.env.VUE_APP_BACKEND_URL +
          "/v1/reporting/device-lost-mode/enable",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.loading = false;
            this.tableData[0] = response.data["devices"].map((item) => ({
              lostModeDeviceName: item[0],
              lostModeSerialNumber: item[1],
              lostModeStorage: item[2],
              lostModeContractStatus: item[3],
              date: new Date(
                new Date(item[4]).getTime() +
                  parseInt(this.gmt_offset.replace("GMT", "")) * 1000
              ).toLocaleString("en-US", {
                weekday: "short",
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }),
              // date: new Date(item[4]).toLocaleString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }),
            }));
          }
        })
        .catch((exception) => {
          console.log(exception);
        });
    },
    loadDisableLostModeData() {
      const axios = require("axios");
      let data = JSON.stringify({
        token: localStorage.token,
      });
      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url:
          process.env.VUE_APP_BACKEND_URL +
          "/v1/reporting/device-lost-mode/disable",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.tableData[1] = response.data["devices"].map((item) => ({
              lostModeDeviceName: item[0],
              lostModeSerialNumber: item[1],
              lostModeStorage: item[2],
              lostModeContractStatus: item[3],
              date: new Date(
                new Date(item[4]).getTime() +
                  parseInt(this.gmt_offset.replace("GMT", "")) * 1000
              ).toLocaleString("en-US", {
                weekday: "short",
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }),
              // date: new Date(item[4]).toLocaleString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }),
            }));
          }
        })
        .catch((exception) => {
          console.log(exception);
        });
    },

    loadLostModeData() {
      setTimeout(() => {
        const axios = require("axios");
        let data = JSON.stringify({
          token: localStorage.token,
        });
        let config = {
          method: "POST",
          maxBodyLength: Infinity,
          url:
            process.env.VUE_APP_BACKEND_URL + "/v1/dashboard/get_all_lost_mode",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            //console.log(JSON.stringify(response.data));

            if (response.data && response.data["200"]) {
              this.tableData[2].forEach((item) => {
                // Add the deviceId to the set of checked device IDs
                let lostModeDeviceName = item.deviceName;
                let lostModeStorage = item.storage;
                let lostModeContractStatus = item.contractStatus;
                let serialNumber = item.serialNumber;

                this.tableData[0] = response.data["200"].map((item) => ({
                  lostModeDeviceName: lostModeDeviceName,
                  lostModeStorage: lostModeStorage,
                  lostModeContractStatus: lostModeContractStatus,
                  date: new Date(
                    new Date(item[2]).getTime() +
                      parseInt(this.gmt_offset.replace("GMT", "")) * 1000
                  ).toLocaleString("en-US", {
                    weekday: "short",
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }),
                  // date: new Date(item[2]).toLocaleString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }),
                  hiddenDateIn: new Date(item[2]).toLocaleString("en-US", {
                    weekday: "short",
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }),
                  hiddenDateOut: new Date(item[2]).toLocaleString("en-US", {
                    weekday: "short",
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }),
                  hiddenSerialNumber: serialNumber,
                }));
                console.log(this.tableData[0][0].lostModeDeviceName);

                response.data["200"].forEach((item) => {
                  if (item[3] != null) {
                    // Assuming response.data["200"] is an array of arrays
                    let filteredData = response.data["200"]
                      .filter((item) => item[3] !== null) // Filter out items where item[3] is not null
                      .map((item) => ({
                        lostModeDeviceName: lostModeDeviceName,
                        lostModeStorage: lostModeStorage,
                        lostModeContractStatus: lostModeContractStatus,
                        date: item[3], // Convert to string if needed
                      }));
                    console.log(filteredData);
                    // Assign filtered data to tableData[1]
                    this.tableData[1] = filteredData;
                  }
                });
              });

              //serialNumber: item[6],
              //msisdn: item[11],
              //usern: item[3],
              //locked: item[14],

              this.success = true; // Set success to true if the data is loaded successfully
            } else {
              console.error("Invalid API response"); // Handle the case when the "200" key is missing or data is not in the expected format                    }
            }
          })

          .catch((error) => {
            if (error instanceof TypeError) {
              if (
                window.location.href.includes("/reporting") &&
                this.lostModeDataCount <= 3
              ) {
                console.log("Loading lost mode data again..");
                this.loadLostModeData();
                console.log(this.lostModeDataCount);
                this.lostModeDataCount++;
              } else {
                console.log(error);
              }
            } else {
              console.log(error);
            }
          });
      }, 500);
    },
    loadCSVData() {
      setTimeout(() => {
        //console.log("token: " + localStorage.token)

        const axios = require("axios");

        let data = JSON.stringify({
          token: localStorage.token,
        });

        let config = {
          method: "POST",
          maxBodyLength: Infinity,
          url: process.env.VUE_APP_BACKEND_URL + "/v1/reporting/get-csv-data",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            //console.log(response.data);
            //console.log("responsedatadata: " + JSON.stringify(response.data[0], null))

            if (
              response.data &&
              response.status == 200 &&
              Array.isArray(this.csvData)
            ) {
              //console.log(JSON.stringify(response.data));

              this.csvData.forEach((data) => {
                // Iterate through each value in response.data.data[index][0]
                response.data.data.forEach((item) => {
                  // Compare CSV_MSISDN with the current targetValue
                  if (data.CSV_MSISDN == item[0]) {
                    //console.log("MATCHHHH");
                    data.CSV_NRIC = item[1]; // Assuming targetValue is an array
                  }
                });

                this.tableData[0].forEach((item) => {
                  let dateIn = new Date(item.hiddenDateIn);
                  let dateOut = new Date(item.hiddenDateOut);
                  if (data.CSV_Serial_Number == item.hiddenSerialNumber) {
                    if (data.CSV_Contract_Status == "Active") {
                      if (item.hiddenDateOut == null) {
                        data.CSV_Last_Action = "-";
                        data.CSV_Date_Action = "-";
                      } else if (item.hiddenDateOut != null) {
                        data.CSV_Last_Action = "Out LM";
                        data.CSV_Date_Action = item.hiddenDateOut;
                      }
                    } else if (
                      data.CSV_Contract_Status == "Barring" ||
                      data.CSV_Contract_Status == "Suspension"
                    ) {
                      if (dateIn > dateOut) {
                        data.CSV_Last_Action = "In LM";
                        data.CSV_Date_Action = item.hiddenDateIn;
                      } else if (dateIn < dateOut) {
                        data.CSV_Last_Action = "Out LM";
                        data.CSV_Date_Action = item.hiddenDateOut;
                      }
                    } else if (data.CSV_Contract_Status == "Terminated") {
                      data.CSV_Last_Action = "In LM";
                      data.CSV_Date_Action = item.hiddenDateIn;
                    }
                  }
                });
              });

              //console.log("csvData:", JSON.stringify(this.csvData, null, 2));
              //console.log("csvData: ", this.csvData);
            } else {
              console.error("Invalid CSV Data API response");
            }
          })

          .catch((error) => {
            if (error instanceof TypeError) {
              if (
                window.location.href.includes("/reporting") &&
                this.csvDataCount < 3
              ) {
                console.log("Loading csv data again..");
                this.csvDataCount++;
                this.loadCSVData();
              } else {
                console.log(error);
              }
            } else {
              console.log(error);
            }
          });
      }, 1000);
    },
    //Load Audit Trail
    loadAuditTrailData() {
      setTimeout(() => {
        //console.log("token: " + localStorage.token)

        const axios = require("axios");

        let data = JSON.stringify({
          token: localStorage.token,
        });

        let config = {
          method: "POST",
          maxBodyLength: Infinity,
          url:
            process.env.VUE_APP_BACKEND_URL +
            "/v1/reporting/get-audit-trail-data",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            //console.log(response.data);
            //console.log("responsedatadata: " + JSON.stringify(response.data[0], null))

            if (response.data && response.status == 200) {
              //console.log(JSON.stringify(response.data));

              this.tableData[3] = response.data["data"].map((item) => ({
                userName: item[0],
                actionLog: item[1],
                serialNumber: item[2],
                namePhone: item[3],
                contractStatus: item[4], // actual name of variable is deviceStatus, i only use contractStatus for functionality sake
                branchCodename: item[5],
                date: new Date(
                  new Date(item[6]).getTime() +
                    parseInt(this.gmt_offset.replace("GMT", "")) * 1000
                ).toLocaleString("en-US", {
                  weekday: "short",
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }),
                // date: new Date(item[6]).toLocaleString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })
              }));
              console.log("Date: ");
              console.log(this.tableData[3][6]["date"]);

              //console.log("csvData:", JSON.stringify(this.csvData, null, 2));
              //console.log("csvData: ", this.csvData);
            } else {
              console.error("Invalid Audit Trail Data API response");
            }
          })

          .catch((error) => {
            if (error instanceof TypeError) {
              if (window.location.href.includes("/reporting")) {
                console.log("Loading audit trail data again..");
                this.loadAuditTrailData();
              } else {
                console.log(error);
              }
            } else {
              console.log(error);
            }
          });
      }, 1000);
      this.success = true;
    },

    checkLogin() {
      //Enable overlay

      //Prep API
      const data = JSON.stringify({
        token: localStorage.token,
      });
      const axios = require("axios");
      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_BACKEND_URL + "/v1/login/validity-check",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      //Run API
      axios
        .request(config)
        .then((response) => {
          if (response.status === 200) {
            this.is_able_to_login = true;
          } else {
            this.is_able_to_login = false;
            this.page_unavailable = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.is_able_to_login = false;
          this.page_unavailable = true;
        });
    },

    async langchange(picker) {
      //console.log(picker)
      //console.log(this.data)
      const response = await fetch("/reporting.json");
      const data = await response.json();
      this.data = data;
      this.lang = this.data[picker];
      this.$refs.BottomFooterRef.langchange(picker);
    },
    pageUnavailableReturn() {
      this.page_unavailable = false;
      this.$router.replace("/dashboard");
    },
    onCloseDemoWarning(){
      this.loading = false;
      this.demoMode = false;
    },

    navigateTables(direction, index) {
      if (direction === "next") {
        this.currentIndex[index] = this.currentIndex[index] + 1;
      } else if (direction === "previous") {
        this.currentIndex[index] = this.currentIndex[index] - 1;
      }
    },

    navigateTables2(index, index2) {
      if(index2 == 4 && localStorage.isDemo == '1'){
        this.demoMode = true;
        this.loading = true;
      }else{
        this.currentIndex[index] = index2;
      }
    },

    buttonAction(index) {
      console.log(index);
    },
  },
  async mounted() {
    await this.getUserGMTOffset();
    this.loadAllData();
  },
};
</script>

<style scoped>
.logo {
  width: 20px;
  cursor: pointer;
}
.layout {
  display: flex;
}
.regdevice_contents {
  flex: 1;
  padding-left: 16px;
  font-family: "Century Gothic";
}
.regdevice_header {
  font-family: "Century Gothic Bold";
  font-weight: bold;
  font-size: 24px;
  margin-top: 5px;
}
.regdevice_secondary_header {
  font-family: "Century Gothic Bold";
  font-weight: bold;
  font-size: 20px;
}
.regdevice_secondary_container {
  margin-top: 5px;
}
.contents_header_text {
  font-size: 14px;
  margin-top: -15px;
}
.form_margin {
  margin-top: -6px;
}
.custom_input_form {
  background-color: #ffffff;
  height: 35px;
  width: 300px;
  text-decoration: none solid rgba(0, 0, 0, 0.87);
  border-color: rgba(1, 48, 66, 0.2) !important;
  word-spacing: 0px;
  letter-spacing: 0.131118px;
  font-size: 12px !important;
  box-sizing: border-box;
  outline: rgba(0, 0, 0, 0.87) none 0px;
}
.searchbar_container {
  display: flex;
}
.custom_input_form:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: rgba(1, 48, 66, 0.8) !important;
  border-width: 2.5px;
  background-color: #ffffff;
  color: #000000;
  font-size: 12px;
}
::placeholder {
  font-size: 12px;
}
.table_container {
  margin-top: 15px;
  max-width: 1400px;
  max-height: 450px;
  overflow-y: auto;
}
.table_container::-webkit-scrollbar {
  width: 8px;
}
.table_container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
.table_container::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 4px;
}
.table_styles {
  width: 100%;
}
thead tr {
  height: 50px;
}
th,
td {
  text-align: center;
  padding: 16px;
}
.odd_row {
  background-color: #ffffff;
}
.even_row {
  background-color: #e6e6e6;
}
.table_header {
  background-color: #cccccc;
  text-align: center;
  border-bottom: 1px solid #989898;
}
.table_body_border {
  border-bottom: 1px solid #989898;
}
.tablehead_text {
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 14px;
  text-align: center;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 9998; /* Below the loading content */
}
.editacc_btn {
  margin-left: 10px;
  width: 100px;
  height: 35px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 11px;
  border: none;
  border-radius: 5px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}
.editacc_btn:hover {
  filter: brightness(90%);
}
.loading-card {
  position: fixed;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 28px;
  filter: brightness(98%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-text {
  justify-items: center;
  display: grid;
  font-family: "Century Gothic", sans-serif;
  text-align: center;
}

.img_style_denied_perms {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  left: 10px;
}

.notiftext_title_denied_perms {
  font-family: "Century Gothic Bold";
  font-size: 16px;
  color: #000000;
  margin-bottom: 2px;
}

.notiftext_denied_perms {
  font-family: "Century Gothic";
  text-align: center !important;
  font-size: 12px;
  color: #000000;
  margin-bottom: 8px;
}

.mandev_btn {
  text-align: center;
  width: 150px;
  height: 30px;
  margin-bottom: 5px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.mandev_btn:hover {
  filter: brightness(90%);
}
@media (min-width: 1900px) {
  .table_container {
    max-width: 1750px;
    max-height: 500px;
  }
}
@media (min-width: 1500px) {
  .table_container {
    max-width: 1400px;
    max-height: 570px;
  }
}
</style>
